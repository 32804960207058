import React from "react";
import { Redirect } from "react-router-dom";

//components
import MainPage from '../components/Main/MainPage';
import ProfilePage from '../components/Profile/ProfilePage';
import EditProfilePage from '../components/Profile/EditProfilePage';
import Medcard from '../components/Profile/Medcard';
import ChatPage from '../components/Chat/ChatPage';
import ActivityPage from '../components/Activity/ActivityPage';
import PostPage from '../components/Post/PostPage';
import QrPage from '../components/Qr/QrPage';

let language = document.querySelector('meta[name="language"]').content;

const authProtectedRoutes = [
  { path: "/"+language+"/medcard/:uid", component: Medcard },
  { path: "/"+language+"/profile/edit", component: EditProfilePage },
  { path: "/"+language+"/profile/:uid", component: ProfilePage },
  { path: "/"+language+"/profile/", component: ProfilePage },
  { path: "/"+language+"/add-post/:uid", component: PostPage },
  { path: "/"+language+"/add-post/", component: PostPage },
  { path: "/"+language+"/activity/", component: ActivityPage },
  { path: "/"+language+"/chats/:uid", component: ChatPage },
  { path: "/"+language+"/chats/", component: ChatPage },
  { path: "/"+language+"/qr/", component: QrPage },
];

const publicRoutes = [
  { path: "/"+language+"/profile/:uid", component: ProfilePage },
  { path: "/"+language+"/", component: MainPage },
  { path: "/", exact: true, component: () => <Redirect to={"/"+language+"/"} /> },
];

export { authProtectedRoutes, publicRoutes };