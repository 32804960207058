import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { withRouter } from "react-router-dom";
import { getMessages, checkMessages } from '../../store/message'
import { NavLink } from 'react-router-dom';
import values from 'lodash/values'
import groupBy from 'lodash/groupBy'
import moment from 'moment'
import ChatArea from './ChatArea'

let language = document.querySelector('meta[name="language"]').content;

class ChatPage extends Component {

    state = {
        profile: this.props.profile,
        loading: true,
        chats: false,
        active: "",
        members: false,
        openChat: false,
        counterMess: 10,
    }

    componentDidMount = () => {
        this.loadMessages()   
    }   

    loadMessages = async () => {
        await this.props.getMessages(this.props.profile.uid)

        this.renderMessages()
        this.loading()
    }

    checkMessages = async () => {
        await this.props.checkMessages()
    
        if(this.props.new_messages.length!==undefined) {
          this.props.navigation.setParams({count: this.props.new_messages.length})
        }
    }

    loading = () => {
        this.setState({loading: false})
    }

    renderMessages = () => {
        let myuid = this.props.profile.uid;
        let myprops = this.props;
        var newData = [];
        var counterMess = 0;
        
        this.props.messages.length && this.props.messages.map(function(item){
            if(item.uid !== myuid) {
                let count = 0;
                if(myprops.new_messages && myprops.new_messages.length>0) {
                    myprops.new_messages.map(function(new_item){
                        if(new_item.sender === item.uid) {
                            count = count + 1;
                        }
                        return true
                    });
                    item.count = count;
                    counterMess = counterMess + count;
                } else {
                    item.count = 0;
                }
                
                newData.push(item);
            }

            return true
        });

        this.setState({chats: values(groupBy(newData, 'members')),counterMess: counterMess})
    }

    chooseChat = (uid,members) => {
        this.setState({active: uid})

        this.props.history.push({
            pathname: "/"+language+"/chats/"+uid,
            messages: members.filter(id => id !== this.props.profile.uid),
        });
    }

    render() {
        let context = this
        if(this.state.loading===true) return (
            <div className="col-md-12 text-center">
                <div className="spinner-border" role="status"></div>
            </div>
        )

        return (
            <div className="chatBox">
                <div className="row">
                    <div className="col-md-4 pr-0">
                        <div className="boxScrollChat-btn" onClick={() => this.setState({openChat:!this.state.openChat})}>
                            <i className="fas fa-comments"></i> Чати {this.state.counterMess>0 && <span className="badge badge-info unreedMesssBage" style={{left: "110px",top:"20px"}}>{this.state.counterMess}</span>}
                        </div>
                        <div className={this.state.openChat?"boxScrollChat open-mobile":"boxScrollChat"}>
                            <div className="col-md-12">
                                { this.state.chats && this.state.chats.map(function(item,key){ console.log(item[0].count)
                                    return(
                                        <NavLink to={"/"+language+"/chats/"+item[0].uid} className="mb-3 lineHeadPost" key={key} onClick={()=>context.chooseChat(item[0].uid,item[0].members)}>
                                            <div className={context.state.active===item[0].uid?"usrrow twoUsRow active":"usrrow twoUsRow"}>
                                                <div className="box-card-avatar">
                                                    <img className="card-avatar" src={item.photo?item.photo:"https://patprofi.pro/img/Missingimage.png"} alt="avatar" />
                                                </div>
                                                {item[0].count>0 && <span className="badge badge-info unreedMesssBage" style={{right: "10px"}}>{item[0].count}</span>}
                                                <div className="lineHeadPost">
                                                    <div className="nameUser">{item[0].username}</div>
                                                    <div className="biUser">{item[0].message}</div>
                                                    <div className="biUser">{moment(item[0].date).format('ll')}</div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 pl-0">
                        <div className="boxChat">
                            { this.props.match.params.uid &&
                                <ChatArea uid={this.props.match.params.uid} messages={this.state.members}/>
                            }
                            { !this.props.match.params.uid &&
                                <div className="col-md-8 offset-md-2 text-center pt-5" style={{color: "#666"}}>
                                    Для початку діалогу - виберіть отримувача у списку зліва. Якщо у Вас ще не було діалогів, Ви можете вибрати отримувача 
                                    <NavLink className="nav-link" to={"/"+language+"/"}>у пошуку</NavLink>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 
        getMessages, checkMessages
    }, dispatch)
}
  
const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        messages: state.messages,
        new_messages: state.check_messages
    }
}
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatPage))