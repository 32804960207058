import firebase from '../config/firebase';
import uuid from 'react-uuid'
import orderBy from 'lodash/orderBy'
import cloneDeep from 'lodash/cloneDeep'

export const updateDescription = (input) => {
	return {type: 'UPDATE_DESCRIPTION', payload: input}
}

export const updatePhoto = (input) => {
	return {type: 'UPDATE_POST_PHOTO', payload: input}
}

export const updateLocation = (input) => {
	return {type: 'UPDATE_LOCATION', payload: input}
}

export const uploadPost = (followers=false,post,user) => {
	return async (dispatch, getState) => {
		try {
      user.followers.push(user.uid);
      
      let user_followers = followers?followers:user.followers

			const id = uuid()
			const upload = {
				id: id,
				postPhoto: post.photo.trim() || '',
				youtube: post.youtube.trim() || '',
				postDescription: post.description || '',
				postLocation: post.location || '',
				uid: user.uid,
				photo: user.photo || '',
        username: user.username,
        date: new Date().getTime(),
				likes: [],
        comments: [],
        feed: user_followers,
        social: null,
			}
			firebase.firestore().collection('posts').doc(id).set(upload)

      return true
		} catch (e) {
			console.error(e)
		}
	}
}

export const deletePost = (uid) => {
  return async () => {
		try {
			await firebase.firestore().collection('posts').doc(uid).delete()

      return true
		} catch (e) {
			console.error(e)
		}
	}
}

export const savePost = (uid,post,user) => {
	return async () => {
		try {
			const upload = {
				postPhoto: post.photo.trim() || '',
				youtube: post.youtube.trim() || '',
				postDescription: post.description.trim() || '',
				photo: user.photo.trim() || '',
        username: user.username.trim(),
			}
			firebase.firestore().collection('posts').doc(uid).update(upload)

      return true
		} catch (e) {
			console.error(e)
		}
	}
}

export const getPosts = (uid=false,limit) => {
	return async (dispatch, getState) => {
		try {
      let initialQuery
      if(uid) {
        initialQuery = await firebase.firestore().collection('posts').where('feed', 'array-contains', uid).orderBy("date", "desc").limit(limit)
      } else {
        initialQuery = await firebase.firestore().collection('posts').where('social', "==", null).orderBy("date", "desc").limit(limit)
      }

      let documentSnapshots = await initialQuery.get();
      let array = documentSnapshots.docs.map(document => document.data());

			dispatch({type: 'GET_POSTS', payload: array})

      return array
		} catch (e) {
			console.log(e)
		}
	}
}

export const getPost = (uid) => {
	return async (dispatch) => {
		try {
      let document = await firebase.firestore().collection('posts').doc(uid).get()
      let data = document.data()

      return data
		} catch (e) {
			console.log(e)
		}
	}
}

export const getPostsMore = (limit,uid,last) => {
	return async () => {
		try {
      let initialQuery
      if(uid) {
        initialQuery = await firebase.firestore().collection('posts').where('feed', 'array-contains', uid).orderBy("date", "desc").startAfter(last).limit(limit)
      } else {
        initialQuery = await firebase.firestore().collection('posts').where('social', "==", null).orderBy("date", "desc").startAfter(last).limit(limit)
      }

      let documentSnapshots = await initialQuery.get();
      let array = documentSnapshots.docs.map(document => document.data());
      console.log(array)

      return array
		} catch (e) {
			console.log(e)
		}
	}
}

export const likePost = (post,user) => {
  return (dispatch, getState) => {
    const { uid, username, photo } = user
    try {
      firebase.firestore().collection('posts').doc(post.id).update({
        likes: firebase.firestore.FieldValue.arrayUnion(uid)
      })
      firebase.firestore().collection('activity').doc().set({
        postId: post.id,
        postPhoto: post.postPhoto,
        likerId: uid,
        likerPhoto: photo,
        likerName: username,
        uid: post.uid,
        date: new Date().getTime(),
        type: 'LIKE',
      })

      dispatch(getPosts(uid))
    } catch(e) {
      console.error(e)
    }
  }
}

export const unlikePost = (post,user) => {
  return async (dispatch, getState) => {
    const { uid } = user
    try {
      firebase.firestore().collection('posts').doc(post.id).update({
        likes: firebase.firestore.FieldValue.arrayRemove(uid)
      })
      const query = await firebase.firestore().collection('activity').where('postId', '==', post.id).where('likerId', '==', uid).get()
      query.forEach((response) => {
        response.ref.delete()
      })
      dispatch(getPosts(uid))
    } catch(e) {
      console.error(e)
    }
  }
}

export const getComments = (post) => {
  return dispatch => {
    dispatch({ type: 'GET_COMMENTS', payload: orderBy(post.comments.reverse(), 'date','desc')})
  }
}

export const addComment = (text, post, user) => {
  return (dispatch, getState) => {
    const { uid, photo, username } = user
    let comments = cloneDeep(post.comments)
    console.log(comments)
    console.log(comments.reverse())
    try {
      const comment = {
        comment: text,
        commenterId: uid,
        commenterPhoto: photo || '',
        commenterName: username,
        date: new Date().getTime(),
      }
      
      firebase.firestore().collection('posts').doc(post.id).update({
        comments: firebase.firestore.FieldValue.arrayUnion(comment)
      })

      comment.postId = post.id
      comment.postPhoto = post.postPhoto
      comment.uid = post.uid
      comment.type = 'COMMENT'

      firebase.firestore().collection('activity').doc().set(comment)

      comments.push(comment)
      dispatch({ type: 'GET_COMMENTS', payload: comments })

      return comments.reverse()
    } catch(e) {
      console.error(e)
    }
  }
}

export const uploadPhoto = (image) => {
    return async (dispatch) => {
      try {
        const uploadTask = await firebase.storage().ref().child(uuid()).put(image)
        const downloadURL = await uploadTask.ref.getDownloadURL()
        return downloadURL
      } catch(e) {
        console.error(e)
      }
    }
}