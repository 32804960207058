import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { withRouter } from "react-router-dom";

class Medcard extends Component {

    state = {
        loading: true,
    }

    componentDidMount() {
        this.loading()
    }

    loading = () => {
        this.setState({loading: false})
    }

    render() {
        if(this.state.loading===true) return (
            <div className="col-md-12 text-center">
                <div className="spinner-border" role="status"></div>
            </div>
        )

        return (
            <div className="col-md-12 text-center boxConnect">
                {/* <h1>Функціонал медкарти у стадії розробки. Дякуємо за розуміння!</h1> */}
                <h3>Функціонал медкарти у стадії розробки. Дякуємо за розуміння!</h3>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 

    }, dispatch)
}
  
const mapStateToProps = (state) => {
    return {
        profile: state.profile
    }
}
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Medcard))