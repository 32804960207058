import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App.js';
import moment from 'moment'
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import reducer from './store/reducer';
import Auth from "./utils/Auth";

const middleware = applyMiddleware(thunkMiddleware);
const store = createStore(reducer, middleware);
require('moment/locale/uk.js');
moment.locale("uk");

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <Auth>
                <App />
            </Auth>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));