import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { withRouter, NavLink } from "react-router-dom";
import { isUserAuthenticated } from '../store/authUtils'; 
import moment from 'moment'
import YouTube from 'react-youtube';
import { likePost, unlikePost, addComment, getComments, deletePost } from '../store/post';
let language = document.querySelector('meta[name="language"]').content;

class Post extends Component {
    
    constructor(props)
    {
        super(props);
        
        let item = props.item
        this.state = {
            like: item.likes,
            openComment: false,
            listComment: item.comments.reverse(),
            comment: ""
        }
    }

    likePost = async (post) => {
        let user = this.props.profile
        let like
        if(this.state.like.includes(user.uid)){
            like = this.state.like.filter(function(value, index, arr){ 
                return value !== user.uid;
            });
            await this.props.unlikePost(post,user)
        } else {
            await this.props.likePost(post,user)
            
            like = this.state.like
            like.push(user.uid);
        }

        this.setState({like})
    }

    postComment = async () => {
        let post = this.props.item
        post.comments = this.state.listComment

        let data = await this.props.addComment(this.state.comment, post, this.props.profile)
        console.log("data",data)
        this.setState({comment: '', listComment: data})
    }

    pressSend = async (event) => {
        if(event.key === 'Enter') {
            await this.postComment()
        }
    }

    renderYoutube = (id) => {
        const opts = {
            height: '300',
            width: '100%',
            playerVars: {
                autoplay: 0,
            },
        }

        return <YouTube videoId={id} opts={opts} onReady={this._onReady} />;
    }

    _onReady(event) {
        event.target.pauseVideo();
    }

    dellPost = async (id) => {
        let isBoss = window.confirm("Ви дійсно хочете видалити цей допис?");
        if(isBoss==true) {
            await this.props.deletePost(id)

            document.location.reload();
        }
    }

    render() {
        let user = isUserAuthenticated()
        let item = this.props.item
        if(!item.photo) item.photo = "https://patprofi.pro/img/Missingimage.png";
        
        const liked = (typeof(this.state.like) !== 'undefined') ? (this.state.like.includes(this.props.profile.uid)) : false;
        let color = liked ? '#c15656' : '#333'
        let icon = liked ? 'fas fa-heart' : 'far fa-heart'

        return (
            <div className="col-md-12">
                <div className="postCotentLine" style={this.props.mb==0?{marginBottom: 0}:{}}>
                    <div className="lineHeadPost usCol plr-25">
                        <div className="row">
                            <div className="col-md-8 usCol">
                            { user &&
                                <NavLink to={item.uid!==this.props.profile.uid?"/"+language+"/profile/"+item.uid:"/"+language+"/profile/"} className="usrrow">
                                    <div className="box-card-avatar">
                                        <img className="card-avatar" src={item.photo} alt="avatar" />
                                    </div>
                                    <div className="rightLinePost">
                                        <div className="nameUser">{item.username}</div>
                                        <div className="datePost">{moment(item.date).format('ll')}</div>
                                        <div className="locationPost">{item.postLocation ? item.postLocation.name : "Україна"}</div>
                                    </div>
                                </NavLink>
                                    
                            }
                            { !user &&
                                <NavLink to={item.uid!==this.props.profile.uid?"/"+language+"/profile/"+item.uid:"/"+language+"/profile/"} className="usrrow">
                                    <div className="box-card-avatar">
                                        <img className="card-avatar" src={item.photo} alt="avatar" />
                                    </div>
                                    <div className="nameUserNoAuth">{item.username}</div>
                                </NavLink>
                            }
                            </div>
                            <div className="col-md-4 usCol">
                                { item.uid==this.props.profile.uid && 
                                <div className="editPostPanel">
                                    <NavLink to={"/"+language+"/add-post/"+item.id} className="editBtn">
                                        <i className="fas fa-pencil-alt"></i>
                                    </NavLink>
                                    <div className="dellBtn" onClick={() => this.dellPost(item.id)}>
                                        <i className="far fa-trash-alt"></i>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="bottomCont">
                        { item.youtube && item.youtube!=" " ? (
                            this.renderYoutube(item.youtube) 
                        ) : (
                            <img className="card-img-top" src={item.postPhoto} alt={item.username} />
                        )}
                        <div className="card-body">
                            <p className="card-text">{item.postDescription}</p>
                            { user &&
                                <div className="d-flex justify-content-between align-items-center likePostLine">
                                    <div className="iconLime" onClick={() => this.likePost(item)}><i className={icon} style={{color: color}}></i> <small className="text-muted">{this.state.like.length} вподобань</small></div>
                                    <div className="iconLime" onClick={() => this.setState({openComment: !this.state.openComment})}><i className="fas fa-comment"></i> <small className="text-muted">{this.state.listComment.length} комментарії</small></div>
                                    { item.uid!==this.props.profile.uid && <NavLink to={"/"+language+"/chats/"+item.uid} className="btn btn-sendChat">Написати</NavLink>}
                                </div>
                            }
                            { this.state.openComment &&
                                <div className="openCommentBox">
                                    <div className="input-group">
                                        <input onKeyPress={this.pressSend} onChange={(el)=>this.setState({comment: el.target.value})} type="text" className="form-control" placeholder="Напишіть комментарій" aria-label="Напишіть комментарій" aria-describedby="basic-addon2" value={this.state.comment}/>
                                        <div className="input-group-append">
                                            <button onClick={() => this.postComment()} className="btn btn-defoult" type="button"><i className="fas fa-paper-plane"></i></button>
                                        </div>
                                    </div>
                                    { this.state.listComment && this.state.listComment.map((item,key) => { 
                                        return (
                                            <div className="line_activity" key={key}>
                                                <div className="col pl-0">
                                                    <img alt="type line" src={item.commenterPhoto?item.commenterPhoto:"https://patprofi.pro/img/Missingimage.png"} />
                                                    <div>
                                                        <div className="mess">{item.commenterName}</div>
                                                        <div className="action">{item.comment}</div>
                                                        <div className="date">{moment(item.date).format('ll')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 
        likePost, unlikePost, addComment, getComments, deletePost
    }, dispatch)
}
  
const mapStateToProps = (state) => {
    return {
        profile: state.profile,
    }
}
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Post))