import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import QRCode from "react-qr-code";
import { withRouter } from "react-router-dom";

class QrPage extends Component {

    state = {
        profile: this.props.profile,
        loading: true,
    }

    componentDidMount() {
        this.loading()
    }

    loading = () => {
        this.setState({loading: false})
    }

    render() {
        let item = this.state.profile
        if(this.state.loading===true) return (
            <div className="col-md-12 text-center">
                <div className="spinner-border" role="status"></div>
            </div>
        )

        return (
            <div className="col-md-12 text-center boxConnect">
                <h1>Швидкий коннєкт</h1>
                <div className="row mb-5 mt-5">
                    <div className="col-md-12 text-center">
                        <QRCode value={item.uid} />
                    </div>
                </div>
                <div className="col-md-6 offset-md-3">Щоб швидко встановити коннєкт з Пацієнтом або Лікарем зчитайте цей код. Для цього відкрийте цю сторінку на своему пристрою, та натисніть кнопку "Сканувати" або на QR.</div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 

    }, dispatch)
}
  
const mapStateToProps = (state) => {
    return {
        profile: state.profile
    }
}
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QrPage))