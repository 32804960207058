import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { NavLink } from 'react-router-dom';
import { checkMessages, buildMessages } from '../../store/message';

class HeaderLayout extends Component {

    state = {
        profile: false
    }

    componentDidMount() {
        this.checkCondition()
    }

    checkCondition = async () => {
        if(this.state.profile===false) {
            if(this.props.profile!==false) {
                await this.props.buildMessages(this.props.profile.uid)
                await this.props.checkMessages(this.props.profile.uid)
                
                this.setState({profile:true})
            }
        }
    }

    render() {
        let language = document.querySelector('meta[name="language"]').content;
        return (
            <header className="headerHi">
                <div className="navbar navbar-light bg-light fixed-top">
                    <div className="container d-flex justify-content-between">
                        <div className="navbar-brand d-flex align-items-center">
                            <NavLink to={"/"+language+"/"}>
                                <img src="/logo.png" className="logoHd" alt="logo" />
                            </NavLink>
                            {/* <strong>PPMed</strong> */}
                            <NavLink to={"/"+language+"/qr/"} className="navStyle nav-link">
                                <i className="fas fa-qrcode"></i>
                            </NavLink>
                        </div>
                        <nav className="nav nav-masthead justify-content-center navStyle">
                            <NavLink className="nav-link" to={"/"+language+"/add-post/"}><i className="fas fa-plus-circle"></i></NavLink>
                            {/* <NavLink className="nav-link" to={"/"+language+"/"}><i className="fas fa-home"></i></NavLink> */}
                            <NavLink className="nav-link" to={"/"+language+"/activity/"}><i className="far fa-compass"></i></NavLink>
                            <NavLink className="nav-link" to={"/"+language+"/profile/"}><i className="fas fa-user"></i></NavLink>
                            <NavLink className="nav-link" to={"/"+language+"/chats/"} style={{position: "relative"}}><i className="fas fa-paper-plane"></i>{ this.props.mess.length>0 && <span className="badge badge-info unreedMesssBage">{this.props.mess.length}</span>}</NavLink>
                        </nav>
                    </div>
                </div>
            </header>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 
        checkMessages, buildMessages
    }, dispatch)
}
  
const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        mess: state.check_messages
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(HeaderLayout)