import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { withRouter } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import { getUserInfo } from '../../../store/user'

class Following extends Component {
    state = {
        following: [],
        loading: true
    }

    componentDidMount() {
        this.getUserInfo();
    }

    getUserInfo = async () => {
        let uid = this.props.url?this.props.url:this.props.profile.uid
        let data = await this.props.getUserInfo(uid,"followers")
        
        this.setState({following: data, loading: false})
    }

    render() {
        let language = document.querySelector('meta[name="language"]').content;
        if(this.state.loading===true) return (
            <div className="col-md-12 text-center">
                <div className="spinner-border" role="status"></div>
            </div>
        )

        return (
            <div className="row">
                <div className="headerPage">Слідкуе</div>
                { this.state.following && this.state.following.map((item,key) => { 
                    return (
                        <NavLink to={"/"+language+"/profile/"+item.uid} className="line_activity" key={key}>
                            <div className="col pl-0">
                                <div className="box-foll-avatar">
                                    <img alt="type line" src={item.photo?item.photo:"https://patprofi.pro/img/Missingimage.png"} />
                                </div>
                                <div>
                                    <div className="mess">{item.username}</div>
                                    <div className="date">{item.role=="user"?"Користувач":"Лікар"}</div>
                                </div>
                            </div>
                        </NavLink>
                    )
                })}
            </div>
        )
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 
        getUserInfo
    }, dispatch)
}
  
const mapStateToProps = (state) => {
    return {
        profile: state.profile,
    }
}
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Following))