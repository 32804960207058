import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment'
let language = document.querySelector('meta[name="language"]').content;

class TypeLine extends Component {
    render() {
        let item = this.props.item
        
        if(item.type==='LIKE') {
          return(          
                <NavLink to={"/"+language+"/profile/"+item.likerId} className="line_activity">
                    <div className="col pl-0">
                        <div className="box-foll-avatar">
                            <img alt="type line" src={item.likerPhoto?item.likerPhoto:"https://patprofi.pro/img/Missingimage.png"} />
                        </div>
                        <div>
                            <div className="mess">{item.likerName}</div>
                            <div className="action">Залишив вподобання</div>
                            <div className="date">{moment(item.date).format('ll')}</div>
                        </div>
                    </div>
                    <div className="box-foll-avatar">
                        <img alt="type line" src={item.postPhoto?item.postPhoto:"https://patprofi.pro/img/Missingimage.png"} className="pr-0 mr-0" />
                    </div>
                </NavLink>
          )
        } else if(item.type==='COMMENT') {
            return (
                <NavLink to={"/"+language+"/profile/"+item.commenterId} className="line_activity">
                    <div className="col pl-0">
                        <div className="box-foll-avatar">
                            <img alt="type line" src={item.commenterPhoto?item.commenterPhoto:"https://patprofi.pro/img/Missingimage.png"} />
                        </div>
                        <div>
                            <div className="mess">{item.commenterName}</div>
                            <div className="action">{item.comment}</div>
                            <div className="date">{moment(item.date).format('ll')}</div>
                        </div>
                    </div>
                    <div className="box-foll-avatar">
                        <img alt="type line" src={item.postPhoto?item.postPhoto:"https://patprofi.pro/img/Missingimage.png"} className="pr-0 mr-0" />
                    </div>
                </NavLink>
            )
        } else if(item.type==='FOLLOWER') {
            return (
                <NavLink to={"/"+language+"/profile/"+item.followerId} className="line_activity">
                    <div className="col pl-0">
                        <div className="box-foll-avatar">
                            <img alt="type line" src={item.followerPhoto?item.followerPhoto:"https://patprofi.pro/img/Missingimage.png"} />
                        </div>
                        <div>
                            <div className="mess">{item.followerName}</div>
                            <div className="action">Підписався на ваші оновлення</div>
                            <div className="date">{moment(item.date).format('ll')}</div>
                        </div>
                    </div>
                    <div className="box-foll-avatar">
                        <img alt="type line" src={item.photo?item.photo:"https://patprofi.pro/img/Missingimage.png"} className="pr-0 mr-0" />
                    </div>
                </NavLink>
            )
        } else return null
    }
}
  
export default TypeLine