import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'

class FooterLayout extends Component {

    state = {}

    componentDidMount() {}

    render() {
        return (
            <footer className="text-muted">
                <div className="container">
                    <p className="float-right">
                        <a href="#top" className="upPat"><i className="fas fa-arrow-up"></i></a>
                    </p>
                    <p>Розроблено за підтримки <a className="copyPat" href="//PATProfi.world" target="_blanck">PATProfi.world</a></p>
                    <div className="appsContainer">
                        <div className="flex social-btns">
                            <a className="app-btn blu flex vert" href="https://apps.apple.com/ua/app/patprofi-med/id1549589568">
                                <i className="fab fa-apple"></i>
                                <p>Available on the <br/> <span className="big-txt">App Store</span></p>
                            </a>

                            <a className="app-btn blu flex vert" href="https://play.google.com/store/apps/details?id=com.pat.med">
                                <i className="fab fa-google-play"></i>
                                <p>Get it on <br/> <span className="big-txt">Google Play</span></p>
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 

    }, dispatch)
}
  
const mapStateToProps = (state) => {
    return {

    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(FooterLayout)