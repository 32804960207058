import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import HeaderLayout from './Header'
import FooterLayout from './Footer'

class BaseLayout extends Component {

    state = {}

    componentDidMount() {}

    render() {
        return (
            <>
                <HeaderLayout />
                <main role="main">
                    <div className="album py-5">
                        <div className="container">
                        {this.props.children}
                        </div>
                    </div>
                </main>
                <FooterLayout />
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 

    }, dispatch)
}
  
const mapStateToProps = (state) => {
    return {

    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout)