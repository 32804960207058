import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { logout, getUser, followUser, unfollowUser } from '../../store/user'
import { withRouter } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import Posts from './components/Posts';
import Followers from './components/Followers';
import Following from './components/Following';

let language = document.querySelector('meta[name="language"]').content;

class ProfilePage extends Component {

    state = {
        profile: this.props.profile,
        loading: true,
        typeContent: 'posts',
    }

    componentDidMount() {
        if(this.props.match.params.uid) {
            this.loadUser(this.props.match.params.uid)
        } else {
            this.loading()
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.match.params.uid!==this.props.match.params.uid && this.props.match.params.uid) {
            this.loadUser(this.props.match.params.uid)

            this.setState({typeContent: 'posts'})
        }
    }

    loading = async () => {
        let data = await this.props.getUser(this.props.profile.uid)

        this.setState({loading: false,profile: data})
    }

    loadUser = async (uid) => {
        let data = await this.props.getUser(uid,"get")

        this.setState({profile:data,loading: false})
    }

    signOut = async () => {
        await this.props.logout()

        this.props.history.push("/"+language+"/");
    }

    follow = async (user) => {
        let result
        if(user.followers.indexOf(this.props.profile.uid) >= 0){
            result = await this.props.unfollowUser(user,this.props.profile)
        } else {
            result = await this.props.followUser(user,this.props.profile)
        }

        let data = await this.props.getUser(user.uid,"get")
        this.setState({profile: data})

        return result
    }

    toggleContent = (type) => {
        this.setState({typeContent: type})
    }

    render() {
        let item = this.state.profile
        
        if(this.state.loading===true) return (
            <div className="col-md-12 text-center">
                <div className="spinner-border" role="status"></div>
            </div>
        )

        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row profileHead">
                            <div className="col-md-12 text-center flexProfile">
                                <div className="box-user-avatar">
                                    <img className="user-avatar" src={item.photo?item.photo:"https://patprofi.pro/img/Missingimage.png"} alt="avatar" />
                                </div>
                                <div className="name">{item.username}</div>
                                <div className="bio">{item.bio?item.bio:""}</div>
                            </div>
                            <div className="col-md-12">
                                <div className="subscribeBox">
                                    <div className="colSubs" onClick={()=>this.toggleContent("posts")}>
                                        <div className="top">{item.posts.length?item.posts.length:0}</div>
                                        <div className="bottom">Пости</div>
                                    </div>
                                    <div className="colSubs" onClick={()=>this.toggleContent("followers")}>
                                        <div className="top">{item.followers.length?item.followers.length:0}</div>
                                        <div className="bottom">Стежать</div>
                                    </div>
                                    <div className="colSubs" onClick={()=>this.toggleContent("following")}>
                                        <div className="top">{item.following.length?item.following.length:0}</div>
                                        <div className="bottom">Слідкую</div>
                                    </div>
                                </div>
                            </div>
                            { !this.props.match.params.uid &&
                                <div className="col-md-12 text-center mt-3">
                                    <NavLink to={"/"+language+"/profile/edit"}><button type="button" className="btn btn-light m-1">Редагувати</button></NavLink>
                                    <button type="button" className="btn btn-light m-1" onClick={()=>this.signOut()}>Вихід</button>
                                    <NavLink to={"/"+language+"/medcard/"+this.state.profile.uid} className="btnMedCard btn btn-light m-1 profileBtnMedcard">Мед карта</NavLink>
                                </div>
                            }
                            { this.props.match.params.uid &&
                                <div className="col-md-12 text-center mt-3">
                                    <button type="button" className="btn btn-light m-1" onClick={() => this.follow(item)}>{item.followers.indexOf(this.props.profile.uid) >= 0 ? 'Не стежити' : 'Стежити'}</button>
                                    <NavLink to={"/"+language+"/chats/"+item.uid}><button type="button" className="btn btn-light m-1">Чат з користувачем</button></NavLink>
                                    <div to={"/"+language+"/medcard/"+this.state.profile.uid} className="btnMedCard btn btn-light m-1 profileBtnMedcard lock"><i className="fas fa-lock"></i> Мед карта</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-md-12">
                        { this.state.typeContent==="posts" &&
                            <Posts item={item} url={this.props.match.params.uid}/>
                        }{ this.state.typeContent==="following" &&
                            <Following item={item} url={this.props.match.params.uid}/>
                        }{ this.state.typeContent==="followers" &&
                            <Followers item={item} url={this.props.match.params.uid}/>
                        }
                    </div>
                </div>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 
        logout, getUser, followUser, unfollowUser
    }, dispatch)
}
  
const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        profile_get: state.geting_profile,
    }
}
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfilePage))