import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import firebase from "../config/firebase"
import { getUser } from '../store/user'
import { withRouter } from "react-router";

class Auth extends React.Component {
    state = {
        loading: true
    }

    componentDidMount() {
        this.loading()
    }
    
    loading = async () => {
        let prop = this
        firebase.auth().onAuthStateChanged(async function(user) {
            if (user) {
                await prop.props.getUser(user.uid)
            } else {
                // No user is signed in.
                console.log('There is no logged in user');
            }

            prop.setState({loading: false})
        });
    }

    render() {
        if(this.state.loading===false) {
            return this.props.children
        } else {
            return (
                <>
                {this.state.loading===true  &&
                    <div className="col-md-12 text-center mt-5">
                        <div className="spinner-border" role="status"></div>
                    </div>
                }
                </>
            )
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 
        getUser
    }, dispatch)
}
  
const mapStateToProps = (state) => {
    return {
    
    }
}
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth))