const cat = [
    { name: 'Експерт', value: 'expert', desc: 'Експерт' },
    { name: 'Всі категорії', value: 'all', desc: 'Всі категорії' },
    { name: 'Лікар з авіаційної медицини', value: 'Лікар з авіаційної медицини', desc: 'Авіаційна та космічна медицина' },
    { name: 'Акушер-гінеколог', value: 'Акушер-гінеколог', desc: 'Акушерство і гінекологія' },
    { name: 'Алерголог', value: 'Алерголог', desc: 'Алергологія' },
    { name: 'Алерголог дитячий', value: 'Алерголог дитячий', desc: 'Дитяча алергологія' },
    { name: 'Анастезіолог', value: 'Анастезіолог', desc: 'Анестезіологія' },
    { name: 'Анастезіолог дитячий', value: 'Анастезіолог дитячий', desc: 'Дитяча анестезіологія' },
    { name: 'Бактеріолог', value: 'Бактеріолог', desc: 'Бактеріологія' },
    { name: 'Вірусолог', value: 'Вірусолог', desc: 'Вірусологія' },
    { name: 'Гастроентеролог', value: 'Гастроентеролог', desc: 'Гастроентерологія' },
    { name: 'Лікар-гастроентеролог дитячий', value: 'Лікар-гастроентеролог дитячий', desc: 'Дитяча гастроентерологія' },
    { name: 'Лікар-гематолог', value: 'Лікар-гематолог', desc: 'Гематологія' },
    { name: 'Гематолог дитячий', value: 'Гематолог дитячий', desc: 'Дитяча гематологія' },
    { name: 'Лікар-генетик', value: 'Лікар-генетик', desc: 'Генетика медична' },
    { name: 'Лікар-геріатр', value: 'Лікар-геріатр', desc: 'Геріатрія' },
    { name: 'Гінеколог дитячого та підліткового віку', value: 'Гінеколог дитячого та підліткового віку', desc: 'Дитяча гінекологія' },
    { name: 'Гінеколог-онколог', value: 'Гінеколог-онколог', desc: 'Онкогінекологія' },
    { name: 'Лікар з гігієни дітей та підлітків', value: 'Лікар з гігієни дітей та підлітків', desc: 'Гігієна дітей та підлітків' },
    { name: 'Лікар з гігієни праці', value: 'Лікар з гігієни праці', desc: 'Гігієна праці' },
    { name: 'Лікар з гігієни харчування', value: 'Лікар з гігієни харчування', desc: 'Гігієна харчування' },
    { name: 'Лікар-дезінфекціоніст', value: 'Лікар-дезінфекціоніст', desc: 'Дезінфекційна справа' },
    { name: 'Дерматовенеролог', value: 'Дерматовенеролог', desc: 'Дерматовенерологія' },
    { name: 'Дерматовенеролог дитячий', value: 'Дерматовенеролог дитячий', desc: 'Дитяча дерматовенерологія' },
    { name: 'Лікар-дієтолог', value: 'Лікар-дієтолог', desc: 'Дієтологія' },
    { name: 'Ендокринолог', value: 'Ендокринолог', desc: 'Ендокринологія' },
    { name: 'Ендокринолог-дитячий', value: 'Ендокринолог-дитячий', desc: 'Дитяча ендокринологія' },
    { name: 'Лікар-ендоскопіст', value: 'Лікар-ендоскопіст', desc: 'Ендоскопія' },
    { name: 'Епідеміолог', value: 'Епідеміолог', desc: 'Епідеміологія' },
    { name: 'Лікар загальної практики - сімейний лікар', value: 'Лікар загальної практики - сімейний лікар', desc: 'Загальна практика - сімейна медицина' },
    { name: 'Лікар із загальної гігієни', value: 'Лікар із загальної гігієни', desc: 'Загальна гігієна' },
    { name: 'Імунолог', value: 'Імунолог', desc: 'Імунологія' },
    { name: 'Лікар-імунолог клінічний', value: 'Лікар-імунолог клінічний', desc: 'Клінічна імунологія' },
    { name: 'Імунолог дитячий', value: 'Імунолог дитячий', desc: 'Дитяча імунологія' },
    { name: 'Інфекціоніст', value: 'Інфекціоніст', desc: 'Інфекційні хвороби' },
    { name: 'Інфекціоніст дитячий', value: 'Інфекціоніст дитячий', desc: 'Дитячі інфекційні хвороби' },
    { name: 'Лікар-інтерн', value: 'Лікар-інтерн', desc: 'Відповідна спеціальність' },
    { name: 'Лікар-кардіолог', value: 'Лікар-кардіолог', desc: 'Кардіологія' },
    { name: 'Кардіоревматолог дитячий', value: 'Кардіоревматолог дитячий', desc: 'Дитяча кардіоревматологія' },
    { name: 'Лікар-комбустіолог', value: 'Лікар-комбустіолог', desc: 'Комбустіологія' },
    { name: 'Лікар з комунальної гігієни', value: 'Лікар з комунальної гігієни', desc: 'Комунальна гігієна' },
    { name: 'Лаборант', value: 'Лаборант', desc: 'Клінічна лабораторна діагностика' },
    { name: 'Лаборант-генетик', value: 'Лаборант-генетик', desc: 'Генетика лабораторна' },
    { name: 'Лаборант-гігієніст', value: 'Лаборант-гігієніст', desc: 'Лабораторні дослідження факторів навколишнього середовища' },
    { name: 'Лікар-лаборант-імунолог', value: 'Лікар-лаборант-імунолог', desc: 'Лабораторна імунологія' },
    { name: 'Лаборант з клінічної біохімії', value: 'Лаборант з клінічної біохімії', desc: 'Клінічна біохімія' },
    { name: 'Лаборант-гігієніст з дослідження хімічних факторів навколишнього середовища', value: 'Лаборант-гігієніст з дослідження хімічних факторів навколишнього середовища', desc: 'Лабораторні дослідження хімічних факторів навколишнього середовища' },
    { name: 'Лаборант-гігієніст з дослідження фізичних факторів навколишнього середовища', value: 'Лаборант-гігієніст з дослідження фізичних факторів навколишнього середовища', desc: 'Лабораторні дослідження фізичних  факторів навколишнього середовища' },
    { name: 'Лікар з лікувальної фізкультури та спортивної медицини', value: 'Лікар з лікувальної фізкультури та спортивної медицини', desc: 'Лікувальна фізкультура і спортивна медицина' },
    { name: 'Лікар-методист', value: 'Лікар-методист', desc: 'Організація і управління охороною здоров`я' },
    { name: 'Мікробіолог-вірусолог', value: 'Мікробіолог-вірусолог', desc: 'Мікробіологія і вірусологія' },
    { name: 'Лікар з народної та нетрадиційної медицини', value: 'Лікар з народної та нетрадиційної медицини', desc: 'Народна та нетрадиційна медицина' },
    { name: 'Нарколог', value: 'Нарколог', desc: 'Наркологія' },
    { name: 'Нарколог дільничний', value: 'Нарколог дільничний', desc: '' },
    { name: 'Невропатолог', value: 'Невропатолог', desc: 'Неврологія' },
    { name: 'Невролог дитячий', value: 'Невролог дитячий', desc: 'Дитяча неврологія' },
    { name: 'Нефролог', value: 'Нефролог', desc: 'Нефрологія' },
    { name: 'Нефролог дитячий', value: 'Нефролог дитячий', desc: 'Дитяча нефрологія' },
    { name: 'Нейрохірург', value: 'Нейрохірург', desc: 'Нейрохірургія' },
    { name: 'Нейрохірург дитячий', value: 'Нейрохірург дитячий', desc: 'Дитяча нейрохірургія' },
    { name: 'Лікар-онколог', value: 'Лікар-онколог', desc: 'Онкологія' },
    { name: 'Онколог дитячий', value: 'Онколог дитячий', desc: 'Дитяча онкологія' },
    { name: 'Ортопед-травматолог', value: 'Ортопед-травматолог', desc: 'Ортопедія і травматологія' },
    { name: 'Ортопед-травматолог дитячий', value: 'Ортопед-травматолог дитячий', desc: 'Дитяча ортопедія і травматологія' },
    { name: 'Лікар-отоларинголог', value: 'Лікар-отоларинголог', desc: 'Отоларингологія' },
    { name: 'Отоларинголог дитячий', value: 'Отоларинголог дитячий', desc: 'Дитяча отоларингологія' },
    { name: 'Отоларинголог-онколог', value: 'Отоларинголог-онколог', desc: 'Онкоотоларингологія' },
    { name: 'Офтальмолог', value: 'Офтальмолог', desc: 'Офтальмологія' },
    { name: 'Офтальмолог дитячий', value: 'Офтальмолог дитячий', desc: 'Дитяча офтальмологія' },
    { name: 'Лікар-паразитолог', value: 'Лікар-паразитолог', desc: 'Паразитологія' },
    { name: 'Патологоанатом', value: 'Патологоанатом', desc: 'Патологічна анатомія' },
    { name: 'Патологоанатом дитячий', value: 'Патологоанатом дитячий', desc: 'Дитяча патологічна анатомія' },
    { name: 'Лікар-педіатр', value: 'Лікар-педіатр', desc: 'Педіатрія' },
    { name: 'Педіатр дільничний', value: 'Педіатр дільничний', desc: '' },
    { name: 'Педіатр неонатолог', value: 'Педіатр неонатолог', desc: 'Неонатологія' },
    { name: 'Лікар приймальної палати (відділення)', value: 'Лікар приймальної палати (відділення)', desc: 'Лікарська спеціальність за  профілем підрозділу' },
    { name: 'Лікар з променевої терапії', value: 'Лікар з променевої терапії', desc: 'Променева терапія' },
    { name: 'Лікар-профпатолог', value: 'Лікар-профпатолог', desc: 'Професійна патологія' },
    { name: 'Психіатр', value: 'Психіатр', desc: 'Психіатрія' },
    { name: 'Психіатр дільничний', value: 'Психіатр дільничний', desc: '' },
    { name: 'Психіатр дитячий', value: 'Психіатр дитячий', desc: 'Дитяча психіатрія' },
    { name: 'Психіатр дитячий дільничний', value: 'Психіатр дитячий дільничний', desc: 'Дитяча психіатрія' },
    { name: 'Лікар-психіатр підлітковий', value: 'Лікар-психіатр підлітковий', desc: '' },
    { name: 'Психіатр підлітковий дільничний', value: 'Психіатр підлітковий дільничний', desc: '' },
    { name: 'Психолог', value: 'Психолог', desc: 'Медична психологія' },
    { name: 'Психотерапевт', value: 'Психотерапевт', desc: 'Психотерапія' },
    { name: 'Психофізіолог', value: 'Психофізіолог', desc: 'Психофізіологія' },
    { name: 'Лікар-пульмонолог', value: 'Лікар-пульмонолог', desc: 'Пульмонологія' },
    { name: 'Пульмонолог дитячий', value: 'Пульмонолог дитячий', desc: 'Дитяча пульмонологія' },
    { name: 'Лікар пункту охорони здоров`я', value: 'Лікар пункту охорони здоров`я', desc: 'Терапія' },
    { name: 'Лікар з радіаційної гігієни', value: 'Лікар з радіаційної гігієни', desc: 'Радіаційна гігієна' },
    { name: 'Лікар-радіолог', value: 'Лікар-радіолог', desc: 'Радіологія' },
    { name: 'Лікар з радіонуклідної діагностики', value: 'Лікар з радіонуклідної діагностики', desc: 'Радіонуклідна діагностика' },
    { name: 'Рентгенолог', value: 'Рентгенолог', desc: 'Рентгенологія' },
    { name: 'Ревматолог', value: 'Ревматолог', desc: 'Ревматологія' },
    { name: 'Рефлексотерапевт', value: 'Рефлексотерапевт', desc: 'Рефлексотерапія' },
    { name: 'Лікар-санолог', value: 'Лікар-санолог', desc: 'Санологія' },
    { name: 'Лікар із спортивної медицини', value: 'Лікар із спортивної медицини', desc: 'Спортивна медицина' },
    { name: 'Сексопатолог', value: 'Сексопатолог', desc: 'Сексопатологія' },
    { name: 'Лікар-статистик', value: 'Лікар-статистик', desc: 'Організація і управління охороною здоров`я' },
    { name: 'Лікар-стажист', value: 'Лікар-стажист', desc: 'Відповідна спеціальність' },
    { name: 'Лікар з медицини невідкладних станів', value: 'Лікар з медицини невідкладних станів', desc: 'Медицина невідкладних станів' },
    { name: 'Стоматолог', value: 'Стоматолог', desc: 'Стоматологія' },
    { name: 'Стоматолог дитячий', value: 'Стоматолог дитячий', desc: 'Дитяча стоматологія' },
    { name: 'Стоматолог-ортодонт', value: 'Стоматолог-ортодонт', desc: 'Ортодонтія' },
    { name: 'Стоматолог-ортопед', value: 'Стоматолог-ортопед', desc: 'Ортопедична стоматологія' },
    { name: 'Стоматолог-терапевт', value: 'Стоматолог-терапевт', desc: 'Терапевтична стоматологія' },
    { name: 'Стоматолог-хірург', value: 'Стоматолог-хірург', desc: 'Хірургічна стоматологія' },
    { name: 'Лікар судновий', value: 'Лікар судновий', desc: 'Суднова медицина' },
    { name: 'Судово-медичний експерт', value: 'Судово-медичний експерт', desc: 'Судово-медична експертиза' },
    { name: 'Судово-медичний експерт імунолог', value: 'Судово-медичний експерт імунолог', desc: 'Судово-медична гістологія' },
    { name: 'Судово-медичний експерт криміналіст', value: 'Судово-медичний експерт криміналіст', desc: 'Судово-медична імунологія' },
    { name: 'Судово-медичний експерт цитолог', value: 'Судово-медичний експерт цитолог', desc: 'Судово-медична криміналістика' },
    { name: 'Судово-медичний експерт токсиколог', value: 'Судово-медичний експерт токсиколог', desc: 'Судово-медична цитологія' },
    { name: 'Судово-психіатричний експерт', value: 'Судово-психіатричний експерт', desc: 'Судово-медична токсикологія' },
    { name: 'Лікар-сурдолог', value: 'Лікар-сурдолог', desc: 'Судово-психіатрична експертиза' },
    { name: 'Терапевт', value: 'Терапевт', desc: 'Сурдологія' },
    { name: 'Терапевт дільничний', value: 'Терапевт дільничний', desc: 'Терапія' },
    { name: 'Терапевт цехової лікарської дільниці', value: 'Терапевт цехової лікарської дільниці', desc: '' },
    { name: 'Терапевт підлітковий', value: 'Терапевт підлітковий', desc: 'Терапія' },
    { name: 'Токсиколог', value: 'Токсиколог', desc: 'Підліткова терапія' },
    { name: 'Трансплантолог', value: 'Трансплантолог', desc: 'Токсикологія' },
    { name: 'Трансфузіолог', value: 'Трансфузіолог', desc: 'Трансплантологія' },
    { name: 'Лікар з ультразвукової діагностики', value: 'Лікар з ультразвукової діагностики', desc: 'Трансфузіологія' },
    { name: 'Уролог', value: 'Уролог', desc: 'Ультразвукова діагностика' },
    { name: 'Уролог дитячий', value: 'Уролог дитячий', desc: 'Урологія' },
    { name: 'Фізіотерапевт', value: 'Фізіотерапевт', desc: 'Дитяча урологія' },
    { name: 'Лікар-фтизіатр', value: 'Лікар-фтизіатр', desc: 'Фізіотерапія' },
    { name: 'Фтизіатр дільничний', value: 'Фтизіатр дільничний', desc: 'Фтизіатрія' },
    { name: 'Фтизіатр дитячий', value: 'Фтизіатр дитячий', desc: 'Дитяча фтизіатрія' },
    { name: 'Лікар з функціональної діагностики', value: 'Лікар з функціональної діагностики', desc: 'Функціональна діагностика' },
    { name: 'Хірург', value: 'Хірург', desc: 'Хірургія' },
    { name: 'Хірург дитячий', value: 'Хірург дитячий', desc: 'Дитяча хірургія' },
    { name: 'Хірург-онколог', value: 'Хірург-онколог', desc: 'Онкохірургія' },
    { name: 'Хірург судинний', value: 'Хірург судинний', desc: 'Судинна хірургія' },
    { name: 'Хірург серцево-судинний', value: 'Хірург серцево-судинний', desc: 'Хірургія серця і магістральних судин' },
    { name: 'Хірург торакальний', value: 'Хірург торакальний', desc: 'Торакальна хірургія' },
    { name: 'Хірург-проктолог', value: 'Хірург-проктолог', desc: 'Проктологія' },
    { name: 'Лікар фізичної та реабілітаційної медицини', value: 'Лікар фізичної та реабілітаційної медицини', desc: 'Фізична та реабілітаційна медицина' },
];

export default cat;