import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import Switch from "react-switch";
import SelectSearch, {fuzzySearch} from 'react-select-search';
import category from '../../store/category.js'
import { signup } from '../../store/user'

class Signup extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            checked: false ,
            options: category,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(checked) {
        this.setState({ checked });
    }

    changeInput = (input) => {
        this.setState({
            category: input
        })
    }
    changeName = (input) => {
        this.setState({
            username: input.target.value
        })
    }
    changeEmail = (input) => {
        this.setState({
            email: input.target.value
        })
    }
    changePass = (input) => {
        this.setState({
            password: input.target.value
        })
    }

    save = async () => {
        const { password,email,username,category } = this.state;
        let role = this.state.checked?"med":"user"
        let user = {
            password,email,username,category,role
        }

        let res = await this.props.signup(user)
        console.log(res)
    }

    render() {
        return (
            <div className="card mb-4 mySearchBox">
                <div className="card-body row">
                    <div className="col-md-12">
                        <form className="form-signin" autoComplete="off">
                            <div className="text-center mb-4">
                                <img className="mb-4" src="/logo.png" alt="" width="100" />
                                <h1 className="h3 mb-3 font-weight-normal">Рєестрація</h1>
                                <div>Якщо Ви вже маєте акаунт - <div className="formHref" onClick={() => this.props.changeForm()}>Авторизуйтеся</div></div>
                            </div>
                            <div className="form-label-group switchBox">
                                <label>
                                    <span>Паціент</span>
                                    <Switch uncheckedIcon={false} checkedIcon={false} onChange={this.handleChange} checked={this.state.checked} />
                                    <span>Лікар</span>
                                </label>
                            </div>

                            { this.state.checked && 
                                <div className="form-label-group signBox">
                                    <SelectSearch search filterOptions={fuzzySearch} options={this.state.options} value="all" name="language" placeholder="Виберіть категорію" onChange={(input) => this.changeInput(input)}/>
                                </div>
                            }
                            <div className="form-label-group">
                                <input autoComplete="off" type="email" id="inputEmail" name="epass" className="form-control" placeholder="Email" required onChange={(input)=>this.changeEmail(input)}/>
                                <label htmlFor="inputEmail">Email</label>
                            </div>
                            <div className="form-label-group">
                                <input autoComplete="off" type="password" id="inputPassword" className="form-control" placeholder="Password" required onChange={(input)=>this.changePass(input)}/>
                                <label htmlFor="inputPassword">Пароль</label>
                            </div>
                            <div className="form-label-group">
                                <input type="text" id="input1" className="form-control" placeholder="Password" required onChange={(input)=>this.changeName(input)}/>
                                <label htmlFor="input1">Ім'я та прізвище</label>
                            </div>

                            <button className="btn btn-lg btn-primary btn-block" type="button" onClick={()=>this.save()}>Рєестрація</button>
                            <p className="mt-5 mb-3 text-muted text-center">&copy; 2020-2021</p>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 
        signup
    }, dispatch)
}
  
const mapStateToProps = (state) => {
    return {
        
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Signup)