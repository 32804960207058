import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import Post from "../../helpers/Post.js"
import RightPanel from "../../helpers/RightPanel.js"
import { getPosts, getPostsMore } from '../../store/post'
import { checkMessages } from '../../store/message';
import firebase from 'firebase';
import '../../config/firebase';

class MainPage extends Component {

    state = {
        posts: [],
        limit: 10,
        end_posts: false,
        profile: false,
        loading: true,
        loading_more: false
    }

    constructor(props)
    {
        super(props);
        let context = this
        firebase.auth().onAuthStateChanged(function(user) {
            if (user === null) {
                context.loadData()
            }
        });
    }

    componentDidUpdate() {
        this.checkCondition()
    }

    loadData = async (uid=false) => {
        let posts
        if(uid)
            posts = await this.props.getPosts(uid,this.state.limit)
        else
            posts = await this.props.getPosts(false,this.state.limit)

        let lastVisible = [];
        if(posts.length > 0) {
            lastVisible = posts[posts.length - 1].date;
        } 

        this.setState({
            posts: posts, 
            lastVisible,
            loading: false
        })
    }

    retrieveMore = async () => {
        if(this.state.posts.length >= this.state.limit && this.state.loading_more!=true) 
        {
            this.setState({loading_more: true});

            let posts = await this.props.getPostsMore(this.state.limit,this.props.profile.uid,this.state.lastVisible)

            if(posts.length>0) 
            {
                let lastVisible = posts[posts.length - 1].date;
                this.setState({
                    posts: [...this.state.posts, ...posts],
                    lastVisible,
                    loading_more: false,
                });
            } else {
                this.setState({loading_more: false,end_posts: true});
            }
        }
    }

    checkCondition = async () => {
        if(this.state.profile===false) {
            if(this.props.profile.uid) {
                await this.loadData(this.props.profile.uid)
                await this.props.checkMessages(this.props.profile.uid)

                this.setState({profile:true})
            }
        }
    }

    render() {
        let state = this.state
        if(this.state.loading===true) return (
            <div className="col-md-12 text-center">
                <div className="spinner-border" role="status"></div>
            </div>
        )

        return (
            <>
                <div className="row">
                    <div className="col-md-7">
                        <div className="row">
                            { this.state.posts.length > 0 && this.state.posts.map(function(item, key) {
                                return(
                                    <Post item={item} key={key} profileCheck={state.profile}/>
                                )
                            })}
                        </div>
                        <div className="boxLoadMore">
                            {this.state.loading_more===true && (
                                <div className="col-md-12 text-center">
                                    <div className="spinner-border" role="status"></div>
                                </div>
                            )}
                            { ( this.state.posts.length >= this.state.limit && this.state.end_posts!==true) ? <div className="btnLoadMore" onClick={()=>this.retrieveMore()}>Завантажити ще</div> : <p>Це усі новини на сьогодні</p> }
                        </div>
                    </div>
                    <div className="col-md-5">
                        <RightPanel profile={this.props.profile}/>
                    </div>
                </div>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 
        getPosts, checkMessages, getPostsMore
    }, dispatch)
}
  
const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        post: state.post,
        new_messages: state.check_messages
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(MainPage)