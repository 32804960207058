import firebase from "firebase";
import ENV from '../env';
require('firebase/storage')
require('firebase/firestore')
require('firebase/auth')

const config = {
  apiKey: ENV.apiKey,
  authDomain: ENV.authDomain,
  databaseURL: ENV.databaseURL,
  projectId: ENV.projectId,
  storageBucket: ENV.storageBucket,
  messagingSenderId: ENV.messagingSenderId
}

firebase.initializeApp(config)

export default firebase