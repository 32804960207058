import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { withRouter } from "react-router-dom";
import { uploadPost, uploadPhoto, getPost, savePost } from "../../store/post";

let language = document.querySelector('meta[name="language"]').content;

class PostPage extends Component {

    state = {
        profile: this.props.profile,
        uid: this.props.match.params.uid?this.props.match.params.uid:false,
        post: {
            photo: "",
            description: "",
            youtube: ""
        },
        tab: "photo",
        loading: true,
        alert: ""
    }

    constructor(props)
    {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    checkEdit = async () => {
        if(this.state.uid) {
            this.setState({loading: true})
            let tab

            let post = await this.props.getPost(this.state.uid)
            if(!post.youtube || post.youtube==" ") { 
                tab = "photo"
            } else {
                tab = "youtube"
            }

            this.setState({
                loading: false,
                post: {
                    photo: post.postPhoto,
                    description: post.postDescription,
                    youtube: post.youtube?"https://www.youtube.com/watch?v="+post.youtube:"",
                },
                tab
            })
        }
    }

    handleChange(selectorFiles)
    {
        var reader = new FileReader();
        reader.readAsDataURL(selectorFiles[0])
        reader.onloadend = function (e) {
            this.setState({
                post: {
                    ...this.state.post,
                    photo: [reader.result],
                    photoFile: selectorFiles[0]
                }
            })
        }.bind(this);
    }

    componentDidMount() {
        this.loading()
        this.checkEdit()
    }

    loading = () => {
        this.setState({loading: false})
    }

    inputChange = (input) => {
        this.setState({post: {
            ...this.state.post,
            description: input.target.value
        }})
    }
    linkChange = (input) => {
        this.setState({post: {
            ...this.state.post,
            youtube: input.target.value
        }})
    }

    savePost = async () => {
        this.setState({loading:true})
        let youtube = " "
        let photo = ' '
        console.log(this.state.post.photoFile)
        if(this.state.post.photoFile) {
            photo = await this.props.uploadPhoto(this.state.post.photoFile)
        }
        if(this.state.post.youtube!="" && this.state.post.youtube!=" ") {
            youtube = this.state.post.youtube.split('=')[1]
        }

        let post = {
            photo,
            description: this.state.post.description,
            youtube
        }

        console.log(post)
        await this.props.savePost(this.state.uid,post,this.state.profile)

        this.setState({loading:false, alert: "Ваш запис оновлено, дякуемо!"})
    }

    addPost = async () => {
        this.setState({loading:true})
        let youtube = ""
        let photo = ""
        
        if(this.state.post.photoFile) {
            photo = await this.props.uploadPhoto(this.state.post.photoFile)
        }
        if(this.state.post.youtube!="") {
            youtube = this.state.post.youtube.split('=')[1]
        }

        let post = {
            photo: photo,
            description: this.state.post.description,
            youtube
        }
        
        await this.props.uploadPost(false,post,this.state.profile)
        this.setState({loading:false})
        this.props.history.push("/"+language+"/");
    }

    changeType = (type) => {
        this.setState({tab:type})
    }

    render() {
        let item = this.state.post
        if(this.state.loading===true) return (
            <div className="col-md-12 text-center">
                <div className="spinner-border" role="status"></div>
                <p style={{marginTop:10}}>Завантаження, зачекайте, будь-ласка.</p>
            </div>
        )

        if(!item.photo) item.photo = "https://patprofi.pro/img/Missingimage.png";

        return (
            <div className="boxWhite">
                <div className="headerPage">Створення запису</div>
                { this.state.alert && <div className="alert alert-success alertPost">{this.state.alert}</div>}
                <div className="col-md-12 mt-3 mb-3">
                    <form method="POST">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="boxTabPost">
                                    <div className={this.state.tab=="photo"?"tab active":"tab"} onClick={()=>this.changeType("photo")}>Фото</div>
                                    <div className={this.state.tab=="youtube"?"tab active":"tab"} onClick={()=>this.changeType("youtube")}>Youtube</div>
                                </div>
                                <div className={this.state.tab=="photo"?"show":"hide"}>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlFile1">Фото запису</label>
                                        <img className="img-fluid" src={item.photo} alt="avatar" />
                                    </div>
                                    <div className="form-group">
                                        <input type="file" accept="image/*" className="form-control-file" id="exampleFormControlFile1" onChange={(e) => this.handleChange(e.target.files)} />
                                    </div>
                                </div>
                                <div className={this.state.tab=="youtube"?"show":"hide"}>
                                    <div className="form-group">
                                        <label htmlFor="input11" className="form-label">Посилання на відео з Youtube</label>
                                        <input type="text" id="input11" className="form-control" onChange={(input) => this.linkChange(input)} value={this.state.post.youtube}/>
                                        <div id="passwordHelpBlock" className="form-text">
                                        Приклад посилання: https://www.youtube.com/watch?v=qDOGyelBHwA
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlTextarea1">Текст запису</label>
                                    <textarea className="form-control postAddTextarea" id="exampleFormControlTextarea1" rows="11" value={item.description} onChange={(input) => this.inputChange(input)}></textarea>
                                </div>
                                { this.state.uid == false ? (
                                    <button type="button" onClick={()=>this.addPost()} className="btn btn-primary">Зберегти</button>
                                ) : (
                                    <button type="button" onClick={()=>this.savePost()} className="btn btn-primary">Оновити</button>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 
        uploadPost, uploadPhoto, getPost, savePost
    }, dispatch)
}
  
const mapStateToProps = (state) => {
    return {
        profile: state.profile
    }
}
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostPage))