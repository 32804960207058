import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { NavLink } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { addMessage, getMessages, dellOlderMessage, checkMessages } from '../../store/message';
import { getUser } from '../../store/user';
import moment from 'moment'
import orderBy from 'lodash/orderBy'

let language = document.querySelector('meta[name="language"]').content;

class ChatArea extends Component {

    state = {
        profile: this.props.profile,
        access_med: true,
        loading: true,
        messages: false,
        message: "",
        chat: false
    }

    componentDidMount() {
        this.loading()
    }

    componentDidUpdate(prevProps) {
        if(this.messagesEnd!==undefined) {
            this.scrollToBottom();
        }
        if(prevProps.match.params.uid!==this.props.uid) {
            this.loading()
        }
    }

    getMess = async () => {
        let user = this.props.messages.filter(message => message.uid===this.props.uid)
        if(!user[0]){
            user = await this.props.getUser(this.props.uid,"get")
        } else {
            user = user[0]
        }

        let mess = this.props.messages.filter(message => message.members.indexOf(this.props.uid) >= 0 && message.members.indexOf(this.props.profile.uid) >= 0)

        this.setState({
            messages: orderBy(mess, 'date','asc'),
            chat: user
        })
    }

    loading = async () => {
        this.getMess()
        this.setState({loading: false})

        let myprop = this.props;
        if(this.props.new_messages && this.props.new_messages.length>0){
            this.props.new_messages.map(function(item){
              if(item.sender === myprop.uid) {
                myprop.dellOlderMessage(item.id)
              }
              return true
            });
        }

        await this.props.checkMessages(this.props.profile.uid)
    }

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' });
    }   

    pressSend = (event) => {
        if(event.key === 'Enter') {
            this.sendMess()
        }
    }

    sendMess = async () => {
        if(this.state.message) {
            let messages = await this.props.addMessage(this.props.uid, this.state.message, this.props.profile)
            let mess = messages.filter(message => message.members.indexOf(this.props.uid) >= 0 && message.members.indexOf(this.props.profile.uid) >= 0)

            this.setState({message: '', messages: orderBy(mess, 'date','asc')})

            this.scrollToBottom();
        }
    }

    render() {
        let context = this
        if(this.state.loading===true) return (
            <div className="col-md-12 text-center">
                <div className="spinner-border" role="status"></div>
            </div>
        )

        return (
            <>
                { this.state.chat &&
                    <div className="HeaderChat">
                        <div className="leftHChat">
                            <div className="nameUser">{this.state.chat.username}</div>
                            <div className="biUser">{moment(this.state.chat.date).format('ll')}</div>
                        </div>
                        { this.state.profile.role!=="user" && <>
                            { this.state.access_med ? (
                                <NavLink to={"/"+language+"/medcard/"+this.state.chat.uid} className="btnMedCard"><i className="fas fa-lock-open"></i> Мед карта</NavLink>
                            ) : (
                                <div className="btnMedCard lock"><i className="fas fa-lock"></i> Мед карта</div>
                            )}
                        </> }
                    </div>
                }
                <div className="BodyChat">
                    {   this.state.messages && this.state.messages.map(function(item,key){
                        return(
                            <div key={key} style={{width: "100%"}}>
                            { item.uid===context.props.profile.uid &&
                                <div className="line me">
                                    <div>
                                        <div className="mess">{item.message}</div>
                                        <div className="date">{moment(item.date).format('ll')}</div>
                                    </div>
                                    <div className="box-card-avatar">
                                        <img alt="chat" src={item.photo?item.photo:"https://patprofi.pro/img/Missingimage.png"} />
                                    </div>
                                </div>
                            }
                            { item.uid===context.props.uid &&
                                <div className="line user">
                                    <div className="box-card-avatar">
                                        <img alt="chat" src={item.photo?item.photo:"https://patprofi.pro/img/Missingimage.png"} />
                                    </div>
                                    <div>
                                        <div className="mess">{item.message}</div>
                                        <div className="date">{moment(item.date).format('ll')}</div>
                                    </div>
                                </div>
                            }
                            </div>
                        )
                    })

                    }

                    <div ref={(el) => { this.messagesEnd = el; }} />
                </div>
                <div className="FooterChat">
                    <div className="input-group">
                        <input onKeyPress={this.pressSend} onChange={(el)=>this.setState({message: el.target.value})} type="text" className="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2" value={this.state.message}/>
                        <div className="input-group-append">
                            <button onClick={() => this.sendMess()} className="btn btn-defoult" type="button"><i className="fas fa-paper-plane"></i></button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 
        addMessage, getMessages, dellOlderMessage, checkMessages, getUser
    }, dispatch)
}
  
const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        messages: state.messages,
        new_messages: state.check_messages
    }
}
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatArea))