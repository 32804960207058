import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { withRouter } from "react-router-dom";
import category from '../../store/category.js'
import { loadLastUsers, loadUsersByCat } from '../../store/user.js'
import { NavLink } from 'react-router-dom';
import SelectSearch, {fuzzySearch} from 'react-select-search';

class Search extends Component {
    state = {
        search_list: [],
        loading: false,
        profile: false,
        val: ""
    }

    componentDidMount() {
        this.loadLastUsers()
    }

    componentDidUpdate() {
        this.checkCondition()
    }

    checkCondition = async () => {
        if(this.state.profile===false) {
            if(this.props.profile!==false) {
                this.setState({profile: true})
                await this.loadLastUsers()
            }
        }
    }

    loadLastUsers = async () => {
        let res = await this.props.loadLastUsers()

        this.setState({search_list: res})
    }

    changeInput = async (input) => {
        let res
        if(input==="all") {
            await this.loadLastUsers()
        } else {
            res = await this.props.loadUsersByCat(input)

            this.setState({search_list: res})
        }

        this.setState({loading: false})
    }

    render() {
        let language = document.querySelector('meta[name="language"]').content;
        if(this.state.loading===true) return (
            <div className="col-md-12 text-center">
                <div className="spinner-border" role="status"></div>
            </div>
        )
        return (
            <div className="card mySearchBox mb-4">
                <div className="card-header">
                    <SelectSearch search filterOptions={fuzzySearch} options={category} value="all" name="language" placeholder="Виберіть категорію" onChange={(input) => this.changeInput(input)} />
                    {/* <input type="text" className="form-search" placeholder="Виберіть категорію" onClick={() => this.openCat()} value={this.state.val?this.state.val:""} onChange={(input)=>this.setState({val:input.target.value})}/> */}
                    {/* { this.state.openCat &&
                        <div className="searchResBox">
                            { category.map(function(item,key){
                                return(
                                    <div key={key} className="listCat" onClick={() => context.chooseCat(item.value, item.name)}>{item.name}</div>
                                )
                            })}
                        </div>
                    } */}
                </div>
                <div className="card-body noPadding">
                    <div className="searchListBox">
                        <div className="col-md-12">
                            { this.state.search_list && this.state.search_list.map(function(item,key){
                                return(
                                    <NavLink to={"/"+language+"/profile/"+item.uid} className="mb-3 lineHeadPost" key={key}>
                                        <div className="usrrow twoUsRow">
                                            <div className="box-card-avatar">
                                                <img className="card-avatar" src={item.photo?item.photo:"https://patprofi.pro/img/Missingimage.png"} alt="avatar" />
                                            </div>
                                            <div className="lineHeadPost titleHeadPost">
                                                <div className="nameUser">{item.username}</div>
                                                <div className="biUser">{item.bio?item.bio:""}</div>
                                            </div>
                                        </div>
                                    </NavLink>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 
        loadLastUsers, loadUsersByCat
    }, dispatch)
}
  
const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        users: state.users,
    }
}
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search))