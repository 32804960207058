import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { updateEmail, updatePassword, login} from '../../store/user'
import { withRouter } from "react-router-dom";

class Login extends Component {

    constructor(props) {
        super(props);
    
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps)
    {
        if(this.props.profile.email){
            let language = document.querySelector('meta[name="language"]').content;

            this.props.history.push("/"+language+"/profile/");
        }
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    render() {
        return (
            <div className="card mb-4 mySearchBox">
                <div className="card-body row">
                    <div className="col-md-12">
                        <form className="form-signin" autoComplete="off" onSubmit={this.handleSubmit}> 
                            <div className="text-center mb-4">
                                <img className="mb-4" src="/logo.png" alt="" width="100" />
                                <h1 className="h3 mb-3 font-weight-normal">Вхід</h1>
                                <div>Якщо Ви ще не маєте акаунту - <div className="formHref" onClick={() => this.props.changeForm()}>Зареєструйтеся</div></div>
                            </div>

                            <div className="form-label-group">
                                <input onChange={input => this.props.updateEmail(input)} autoComplete="off" type="email" id="inputEmail" name="epass" className="form-control" placeholder="Email address" required />
                                <label htmlFor="inputEmail">Email address</label>
                            </div>

                            <div className="form-label-group">
                                <input onChange={input => this.props.updatePassword(input)} autoComplete="off" type="password" id="inputPassword" className="form-control" placeholder="Password" required />
                                <label htmlFor="inputPassword">Password</label>
                            </div>

                            <button className="btn btn-lg btn-primary btn-block" onClick={() => this.props.login()}>Вхід</button>
                            <p className="mt-5 mb-3 text-muted text-center">&copy; 2020-2021</p>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 
        updateEmail, updatePassword, login
    }, dispatch)
}
  
const mapStateToProps = (state) => {
    return {
        user: state.user,
        profile: state.profile,
    }
}
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))