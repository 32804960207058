import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { withRouter } from "react-router-dom";
import Post from "../../../helpers/Post.js"

class Posts extends Component {
    state = {
        post: false,
        modal: "fade"
    }

    showPost = (item) => {
        document.getElementsByTagName("body")[0].style.overflowY = "hidden"
        this.setState({modal:"modal show",post: <Post mb={0} item={item} key={1} profileCheck={this.props.profile}/>})
    }

    closePost = () => {
        document.getElementsByTagName("body")[0].style.overflowY = "auto"
        this.setState({modal:"modal fade",post: false})
    }

    render() {
        let context = this
        let item = this.props.item

        if(this.state.loading===true) return (
            <div className="col-md-12 text-center">
                <div className="spinner-border" role="status"></div>
            </div>
        )

        return (
            <div className="row">
                { item.posts.map(function(it,key){
                    return(
                        <div className="col-md-4" key={key} data-toggle="modal" data-target="#postModal" onClick={()=>context.showPost(it)}>
                            { it.youtube && it.youtube!=" " ? (
                                <div className="postPhotoProfile youtubePostBox">
                                    <i className="fab fa-youtube youtubePost"></i>
                                </div>
                            ) : (
                                <div className="postPhotoProfile" style={{backgroundImage: 'url("'+it.postPhoto+'")'}}></div>
                            )}
                        </div>
                    )
                })}
                <div className={this.state.modal} id="postModal" tabIndex="-1" role="dialog" aria-labelledby="postModal" aria-hidden="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Просмотр запису</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>context.closePost()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body row">
                                { this.state.post===false ? (
                                    <div className="col-md-12 text-center">
                                        <div className="spinner-border" role="status"></div>
                                    </div>
                                ) : ( 
                                    this.state.post 
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 

    }, dispatch)
}
  
const mapStateToProps = (state) => {
    return {
        profile: state.profile,
    }
}
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Posts))