import firebase from '../config/firebase';

//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
}

//Set the logged in user data in local session 
const setLoggeedInUser = (user) => {
    localStorage.setItem('user_ppmed', JSON.stringify(user));
}

const setLoggeedOutUser = () => {
    localStorage.setItem('user_ppmed', null);
}

const getLoggedInUser = () => {
    let user = firebase.auth().currentUser
    // if(!localStorage.getItem('user_ppmed')) return null;
    // return JSON.parse(localStorage.getItem('user_ppmed'));
    return user
}

export { setLoggeedInUser, getLoggedInUser, isUserAuthenticated, setLoggeedOutUser }