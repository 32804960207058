// import { BASE_URL } from './actionTypes';
import firebase from '../config/firebase';
import uuid from 'react-uuid'
import orderBy from 'lodash/orderBy'
// import { setLoggeedInUser } from '../store/authUtils'; 

export const updateEmail = (email) => {
	return {type: 'UPDATE_EMAIL', payload: email.target.value}
}

export const updatePassword = (password) => {
	return {type: 'UPDATE_PASSWORD', payload: password.target.value}
}

export const updateUsername = (username) => {
	return {type: 'UPDATE_USERNAME', payload: username}
}

export const updateBio = (bio) => {
	return {type: 'UPDATE_BIO', payload: bio}
}

export const updatePhoto = (photo) => {
	return {type: 'UPDATE_USER_PHOTO', payload: photo}
}

export const updateCategory = (cat) => {
	return {type: 'UPDATE_USER_CAT', payload: cat}
}

export const updateRole = (role) => {
	return {type: 'UPDATE_USER_ROLE', payload: role}
}

export const updateUser = (user) => {
	return async ( dispatch, getState )  => {
	  const { uid, username, bio, photo } = user
	  try {
		firebase.firestore().collection('users').doc(uid).update({
		  username: username,
		  bio: bio,
		  photo: photo
		})

		return dispatch(getUser(uid))
	  } catch(e) {
		alert(e)
	  }
	}
}

export const logout = () => {
    firebase.auth().signOut();

    // setLoggeedOutUser();

	return {type: 'LOGOUT', payload: ""}
}

export const login = () => {
	return async (dispatch, getState) => {
		try {
			const { email, password } = getState().user
			const response = await firebase.auth().signInWithEmailAndPassword(email.trim(), password.trim())
			dispatch(getUser(response.user.uid))
		} catch (e) {
			alert(e)
		}
	}
}

export const getUser = (uid, type) => {
	return async (dispatch, getState) => {
		try {
			const userQuery = await firebase.firestore().collection('users').doc(uid).get()
			let user = userQuery.data()

			let posts = []
			const postsQuery = await firebase.firestore().collection('posts').where('uid', '==', uid).where('social', '==', null).get()
			postsQuery.forEach(function(response) {
				posts.push(response.data())
			})
			user.posts = orderBy(posts, 'date','desc')

			if(type === 'get') {
				// dispatch({type: 'GET_PROFILE_USER', payload: user })
                return user
			} else {
                // setLoggeedInUser(user)
                // console.log(user)
				dispatch({type: 'GET_PROFILE', payload: user })
				return user
			}
		} catch (e) {
			console.log(e)
			alert(e)
		}
	}
}

export const signup = (user) => {
	return async (dispatch) => {
		try {
			const { email, password, username, role, category } = user
			const response = await firebase.auth().createUserWithEmailAndPassword(email, password)
			if(response.user.uid) {
				const user = {
					uid: response.user.uid,
					email: email,
					username: username,
					bio: null,
					photo: '',
					token: null,
					category: category?category:null,
					role: role,
					followers: [],
					following: [],
					date: new Date().getTime()
				}
				firebase.firestore().collection('users').doc(response.user.uid).set(user)
				
				/* Start add first post */ 
				let followers = []
				const id = uuid()
				followers.push(response.user.uid);

				const upload = {
					id: id,
					postPhoto: "https://firebasestorage.googleapis.com/v0/b/patprofi-med.appspot.com/o/post.jpg?alt=media&token=843c8d0c-6ff6-4a27-ad58-f8fd5455d653",
					postDescription: "Ласкаво просимо! Я - ваш перший пост. Але не хвилюйтеся, ніхто мене крім вас не побачить. Ви можете додати свій пост, або знайти в пошуку лікарів, з якими встановіть коннект. Не забувайте читати корисну інформацію, яку публікують наші фахівці.",
					postLocation: '',
					uid: "Y0Y3eeaI6bd8Bi3ntYsGSspp1Iq1",
					photo: 'https://firebasestorage.googleapis.com/v0/b/patprofi-med.appspot.com/o/icon.png?alt=media&token=1a815230-6784-44c8-8848-cfb97555b125',
					username: "Pat Profi Med",
					date: new Date().getTime(),
					likes: [],
					comments: [],
					feed: followers,
					social: true,
				}
				firebase.firestore().collection('posts').doc(id).set(upload)

				dispatch({type: 'LOGIN', payload: user})

				return user
			}
		} catch (e) {
			alert(e)
		}
	}
}

export const loadLastUsers = () => {
	return async (dispatch, getState) => {
        let search = []
        const query = await firebase.firestore().collection('users')
                    .orderBy('date','desc')
                    .where('role', '==', 'med')
                    .limit(100)
                    .get()

        query.forEach((response) => {
            search.push(response.data())
        })

        dispatch({type: 'LAST_USERS', payload: search})
		return search
    }
}

export const loadUsersByCat = (value) => {
	return async (dispatch, getState) => {
        let search = []
        const query = await firebase.firestore().collection('users')
            .orderBy('date','desc')
            .where('category', '==', value)
            .where('role', '==', 'med')
            .limit(25)
            .get()

        query.forEach((response) => {
            search.push(response.data())
        })

        dispatch({type: 'LAST_USERS', payload: search})

		return search
    }
}
export const getUserActivity = (uid) => {
	return async (dispatch) => {
        let activity = []
        const query = await firebase.firestore().collection('activity').where('uid', '==', uid).limit(50).get()
        
        query.forEach((response) => {
            activity.push(response.data())
        })

        return activity
    }
}

export const getUserInfo = (uid,type) => {
	return async (dispatch) => {
        let data = []
        const query = await firebase.firestore().collection('users').where(type, 'array-contains', uid).limit(100).get()
        
		query.forEach((response) => {
            data.push(response.data())
        })

        return data
    }
}

export const followUser = (user,follower) => {
	return async ( dispatch, getState ) => {
	  const { uid, photo, username } = follower
	  try {
		firebase.firestore().collection('users').doc(user.uid).update({
			followers: firebase.firestore.FieldValue.arrayUnion(uid)
		})
		firebase.firestore().collection('users').doc(uid).update({
			following: firebase.firestore.FieldValue.arrayUnion(user.uid)
		})

		firebase.firestore().collection('activity').doc().set({
		  followerId: uid,
		  followerPhoto: photo,
		  followerName: username,
		  uid: user.uid,
		  photo: user.photo,
		  username: user.username,
		  date: new Date().getTime(),
		  type: 'FOLLOWER',
		})

		return dispatch(getUser(user.uid,"get"))
	  } catch(e) {
		console.error(e)
	  }
	}
}
  
export const unfollowUser = (user,follower) => {
	return async ( dispatch, getState ) => {
	  const { uid } = follower
	  try {
			firebase.firestore().collection('users').doc(user.uid).update({
				followers: firebase.firestore.FieldValue.arrayRemove(uid)
			})
			firebase.firestore().collection('users').doc(uid).update({
				following: firebase.firestore.FieldValue.arrayRemove(user.uid)
			})

			return dispatch(getUser(user.uid,"get"))
	  } catch(e) {
		console.error(e)
	  }
	}
}