import orderBy from 'lodash/orderBy'
import firebase from '../config/firebase';
import uuid from 'react-uuid'

export const addMessage = (id, text, user) => {
  return (dispatch, getState) => {
    const { uid, photo, username } = user
    
    try {
      const id_mess = uuid()
      const message = {
        id: id_mess,
        members: [id, uid].sort(),
        message: text,
        photo: photo,
        username: username,
        uid: uid,
        date: new Date().getTime(),
      }
      firebase.firestore().collection('messages').doc(id_mess).set(message)
      
      firebase.firestore().collection('new_messages').doc(id_mess).set({
        id: id_mess,
        sender: uid,
        uid: id,
        chatPhoto: photo,
        text: text,
        date: new Date().getTime(),
        type: 'MESSAGE',
      })
      
      return dispatch(getMessages(uid))
    } catch(e) {
      console.error(e)
    }
  }
}

export const dellOlderMessage = (uid) => {
  return async (dispatch, getState) => {
    firebase.firestore().collection("new_messages").doc(uid).delete().then(function() {
      console.log("Document successfully deleted!");
    }).catch(function(error) {
      console.error("Error removing document: ", error);
    });
  }
}

export const getMessages = (uid) => {
  return async (dispatch, getState) => {
    // const { uid } = getState().user
    let messages = []
    try {
      const query = await firebase.firestore().collection('messages').where('members', 'array-contains', uid).get()
      query.forEach((response) => {
        let message = response.data()
        messages.push(message)
      })
      dispatch({ type: 'GET_MESSAGES', payload: orderBy(messages, 'date','desc')})

      return orderBy(messages, 'date','desc')
    } catch(e) {
      console.error(e)
    }
  }
}

export const buildMessages = (uid) => {
  return async (dispatch) => {
    let messages = []

    dispatch({ type: 'GET_MESSAGES', payload: messages})
  }
}

export const checkMessages = (uid) => {
  return async (dispatch) => {
    if(uid) {
      let messages = []
      try {
        const query = await firebase.firestore().collection('new_messages').where('uid', '==', uid).get()
        query.forEach((response) => {
          let message = response.data()
          messages.push(message)
        })
        dispatch({ type: 'GET_NEW_MESSAGES', payload: orderBy(messages, 'date','desc')})
      } catch(e) {
        console.error(e)
      }
    }
  }
}