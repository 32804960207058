const ENV = {
    apiKey: "AIzaSyA8c-iAxyO2GVbzrT0WL2iAnzueXPG-agc",
    authDomain: "patprofi-med.firebaseapp.com",
    databaseURL: "https://patprofi-med.firebaseio.com",
    projectId: "patprofi-med",
    storageBucket: "patprofi-med.appspot.com",
    messagingSenderId: "462031572889",
    appId: "1:462031572889:web:ae74f2026f774fc7b28d08",
    measurementId: "G-DZHEG59R8P",
    googleApiKey: "AIzaSyCXm_Pjy6dEQkr3j0ujegNvGD3WQK6X3VE"
}

export default ENV;