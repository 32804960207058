import React from "react";
import { Route, Redirect } from "react-router-dom";

// Get all Auth methods
import { isUserAuthenticated } from '../store/authUtils'; 

let language = document.querySelector('meta[name="language"]').content;
const AppRoute = ({
  component: Component,
  isAuthProtected,
  layout: Layout,
  path: Path,
  userAuth,
  ...rest
}) => (
  <Route  
    {...rest}
    render={props => {
      // if (isAuthProtected && !isUserAuthenticated()) {
      //   return (
      //     <Redirect to={{ pathname: "/"+language+"/", state: { from: props.location } }} exact />
      //   );
      // }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default AppRoute;

