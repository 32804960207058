import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { getUserActivity } from '../../store/user'
import { withRouter } from "react-router-dom";
import TypeLine from './TypeLine'
import orderBy from 'lodash/orderBy'

class ActivityPage extends Component {

    state = {
        profile: this.props.profile,
        loading: true,
        list: []
    }

    componentDidMount() {
        this.loading()
    }

    loading = async () => {
        let data = await this.props.getUserActivity(this.state.profile.uid)

        this.setState({loading: false, list: orderBy(data, 'date','desc')})
    }

    render() {
        if(this.state.loading===true) return (
            <div className="col-md-12 text-center">
                <div className="spinner-border" role="status"></div>
            </div>
        )

        return (
            <div className="boxWhite">
                <div className="headerPage">Активніть у Вашому аккаунті</div>
                { this.state.list && this.state.list.map((item,key) => { 
                    return (
                        <TypeLine item={item} key={key}/>
                    )
                })}
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 
        getUserActivity
    }, dispatch)
}
  
const mapStateToProps = (state) => {
    return {
        profile: state.profile
    }
}
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivityPage))