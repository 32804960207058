import { combineReducers  } from 'redux';

// const initialState = {
//     profile: false
// }

const user = (state = {}, action) => {
    switch (action.type) {
        case 'LOGIN':
            return false
        case 'UPDATE_EMAIL':
            return {...state, email: action.payload}
        case 'UPDATE_PASSWORD':
            return {...state, password: action.payload}
        case 'UPDATE_USERNAME':
            return {...state, username: action.payload}
        case 'UPDATE_BIO':
            return {...state, bio: action.payload}
        case 'UPDATE_USER_PHOTO':
            return { ...state, photo: action.payload }
        case 'UPDATE_USER_CAT':
            return { ...state, category: action.payload }
        case 'UPDATE_USER_ROLE':
            return { ...state, role: action.payload }
        default:
            return state
    }
}

const instate = {
    false: true,
    photo: ""
}
const profile = (state=instate, action) => {
    switch (action.type) {
        case 'GET_PROFILE':
            return action.payload
        case 'LOGOUT':
            return action.payload
        default:
            return state
    }
}

const messages = (state = {}, action) => {
    switch (action.type) {
        case 'GET_MESSAGES':
        return action.payload
        default:
        return state
    }
}

const check_messages = (state = {}, action) => {
    switch (action.type) {
        case 'GET_NEW_MESSAGES':
        return action.payload
        default:
        return state
    }
}
  
const post = (state=null, action) => {
    switch (action.type) {
        case 'UPDATE_POST_PHOTO':
        return { ...state, photo: action.payload }
        case 'UPDATE_DESCRIPTION':
        return {...state, description: action.payload}
        case 'UPDATE_LOCATION':
        return { ...state, location: action.payload }
        case 'GET_POSTS':
        return {...state, feed: action.payload}
        case 'GET_COMMENTS': 
        return { ...state, comments: action.payload }
        default:
        return state
    }
}

const users = (state=null, action) => {
    switch (action.type) {
        case 'LAST_USERS':
            return action.payload
        default:
            return state
    }
}

const rootReducer = combineReducers({
    post,
    user,
    users,
    profile,
    messages,
    check_messages
});

export default rootReducer;