import React, { Component } from 'react';
import { isUserAuthenticated } from '../store/authUtils'; 
import Search from './right_panel/Search'; 
import Login from './right_panel/Login'; 
import Signup from './right_panel/Signup'; 

class RightPanel extends Component {
    state = {
        type: "login"
    }

    changeForm = () => {
        let type
        if(this.state.type==="login") {
            type = "signup"
        } else {
            type = "login"
        }
        
        this.setState({
            type: type
        })
    }

    render() {
        let user = isUserAuthenticated()

        return (
            <>
                { user &&
                    <Search />
                }
                { !user && this.state.type==="login" &&
                    <Login changeForm={this.changeForm}/>
                }
                { !user && this.state.type==="signup" &&
                    <Signup changeForm={this.changeForm}/>
                }
            </>
        )
    }
}
  
export default RightPanel