import React, { Component } from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";

import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";
import BaseLayout from "./components/Layout/BaseLayout";

// Import scss
import "./theme.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            {(this.props.profile != undefined && this.props.profile.false !=true) && authProtectedRoutes.map((route, idx) => (
                <AppRoute
                    path={route.path}
                    component={route.component}
                    layout={BaseLayout}
                    key={idx}
                    isAuthProtected={true}
                />
            ))}
            {publicRoutes.map((route, idx) => (
                <AppRoute
                    path={route.path}
                    component={route.component}
                    layout={BaseLayout}
                    key={idx}
                    isAuthProtected={false}
                />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ 

  }, dispatch)
}

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
