import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { withRouter } from "react-router-dom";
import { uploadPhoto } from "../../store/post";
import { updateUser } from "../../store/user";
let language = document.querySelector('meta[name="language"]').content;

class EditProfilePage extends Component {

    state = {
        profile: this.props.profile,
        loading: true,
        user: this.props.profile
    }

    constructor(props)
    {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(selectorFiles)
    {
        var reader = new FileReader();
        reader.readAsDataURL(selectorFiles[0])
        reader.onloadend = function (e) {
            this.setState({
                user: {
                    ...this.state.user,
                    photo: [reader.result],
                    photoFile: selectorFiles[0]
                }
            })
        }.bind(this);
    }

    componentDidMount() {
        this.loading()
    }

    loading = () => {
        this.setState({loading: false})
    }

    inputChange = (input) => {
        this.setState({user: {
            ...this.state.user,
            bio: input.target.value
        }})
    }
    nameChange = (input) => {
        this.setState({user: {
            ...this.state.user,
            username: input.target.value
        }})
    }

    save = async () => {
        this.setState({loading:true})
        let photo
        if(this.state.user.photoFile) {
            photo = await this.props.uploadPhoto(this.state.user.photoFile)
        }
        
        let user = {
            ...this.state.user,
            photo: photo?photo:this.state.profile.photo,
        }

        await this.props.updateUser(user)
        this.setState({loading:false})

        this.props.history.push("/"+language+"/profile/");
    }

    render() {
        let item = this.state.user
        if(!item.photo) item.photo = "https://patprofi.pro/img/Missingimage.png";
        if(this.state.loading===true) return (
            <div className="col-md-12 text-center">
                <div className="spinner-border" role="status"></div>
            </div>
        )

        return (
            <div className="boxWhite">
                <div className="headerPage">Редагування профайлу</div>
                <div className="col-md-12 mt-3 mb-3">
                    <form method="POST">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlFile1">Фото</label>
                                    <img className="img-fluid" src={item.photo} alt="avatar" />
                                </div>
                                <div className="form-group">
                                    <input type="file" accept="image/*" className="form-control-file" id="exampleFormControlFile1" onChange={(e) => this.handleChange(e.target.files)} />
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControl1">Email</label>
                                    <input type="text" className="form-control postAddTextarea" aria-label="Email" id="exampleFormControl1" value={item.email} onChange={this.email} readOnly/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleFormControl2">Ім'я</label>
                                    <input type="text" className="form-control postAddTextarea" aria-label="Ім'я" id="exampleFormControl2" value={item.username} onChange={(input) => this.nameChange(input)}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlTextarea1">Опис профілю</label>
                                    <textarea className="form-control postAddTextarea" id="exampleFormControlTextarea1" rows="11" value={item.bio} onChange={(input) => this.inputChange(input)}></textarea>
                                </div>
                                <button type="button" onClick={()=>this.save()} className="btn btn-primary">Зберегти</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 
        uploadPhoto, updateUser
    }, dispatch)
}
  
const mapStateToProps = (state) => {
    return {
        profile: state.profile
    }
}
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditProfilePage))